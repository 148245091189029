window.hub = window.hub || {};

hub.common = hub.common || {};

hub.common.initializeDragDrop = (parentSelector, orderingCallback) => {
    let draggedElement = null;

    // Function to handle dragstart event
    function start(event) {
        draggedElement = event.target.closest('[drag-item]');
        event.dataTransfer.effectAllowed = "move";
    }

    // Function to handle dragover event
    function dragover(event) {
        event.preventDefault(); // Necessary for allowing dropping
        const targetElement = event.target.closest('[drag-item]');

        if (targetElement && targetElement !== draggedElement) {
            // Convert HTMLCollection to Array
            const children = Array.from(parentElement.querySelectorAll('[drag-item]'));

            // Find indices of dragged and target elements
            const draggedIndex = children.indexOf(draggedElement);
            const targetIndex = children.indexOf(targetElement);

            if (targetIndex > draggedIndex) {
                targetElement.after(draggedElement);
            } else {
                targetElement.before(draggedElement);
            }
        }
    }

    // Function to handle drop event
    function drop(event) {
        event.preventDefault(); // Prevent default drop behavior
        if (draggedElement) {
            updateOrdering();
            draggedElement = null; // Reset dragged element after successful drop
        }
    }

    // Function to update ordering
    function updateOrdering() {
        const ordering = Array.from(parentElement.querySelectorAll('[drag-item]')).map(el => el.dataset.id);
        orderingCallback(ordering); // Call the callback to update the ordering
    }

    // Attach event listeners to the parent element
    const parentElement = document.querySelector(parentSelector);
    parentElement.addEventListener('dragstart', start);
    parentElement.addEventListener('dragover', dragover);
    parentElement.addEventListener('drop', drop);

    // Ensure all child items are draggable
    parentElement.querySelectorAll('[drag-item]').forEach(item => item.setAttribute('draggable', true));
}


$(window).on('load', function() {
    var domain = window.location.hostname,
        hubURL = 'https://hub.pacificenergy.com.au';

    $('.main-header .fa-power-off')
        .parents('.nav-link')
        .addClass('d-none');
    
    if (domain.includes('backend')) {
        hubURL = 'https://hub.testing.sea.cirrenalabs.com.au';
    }

    $('.main-header .fa-power-off')
        .parents('.nav-item')
        .append(`
            <div class="dropdown">
                <button class="btn dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                    Account Options
                </button>

                <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" href="` + hubURL + `" target="_blank">Visit The Hub</a>
                    <a class="dropdown-item" href="#" onclick="event.preventDefault(); $('.main-header .fa-power-off').parents('.nav-link').click();">Sign Out</a>
                </div>
            </div>
        `);
});
